import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowRightIcon, ArrowTopRightOnSquareIcon, } from "@heroicons/react/24/outline";
import { Button } from "../components/button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Drawer, DrawerContent } from "../components/drawer_dialog";
import { AccountPage } from "../crm/account_details";
import { useContext } from "react";
import { UserContext } from "../App";
import OmniSearch from "./omni_search";
import AccountLogo from "../crm/account_details/account_logo";
const WideScreen = ({ accounts }) => {
    const navigate = useNavigate();
    const { accountIdParam } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const hasAccountIdParam = accountIdParam !== undefined;
    const { userGivenName } = useContext(UserContext);
    return (_jsxs("div", Object.assign({ className: "flex flex-col w-full h-full space-y-8" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex flex-col bg-wds-blue-5 text-white rounded-lg px-6 pt-4 pb-6 border b-wds-gray-3 space-y-2" }, { children: [_jsx("div", Object.assign({ className: "flex text-3xl" }, { children: userGivenName ? "Hi, " + userGivenName : "Welcome to Wiser" })), _jsxs("div", Object.assign({ className: "text-lg" }, { children: ["Today is", " ", new Date().toLocaleDateString("en-US", {
                                month: "long",
                                day: "numeric",
                                weekday: "long",
                            })] })), _jsx(OmniSearch, {})] })), _jsxs("div", Object.assign({ className: "pt-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center pb-4" }, { children: [_jsx("div", Object.assign({ className: "text-2xl font-semibold" }, { children: "Recent accounts" })), _jsxs(Button, Object.assign({ variant: "outline", size: "lg", className: "ml-4 font-bold text-base h-8 bg-wds-gray-1", onClick: (e) => {
                                    e.stopPropagation();
                                    navigate(`/crm/home/accounts?${searchParams.toString()}`);
                                } }, { children: ["All accounts", _jsx(ArrowTopRightOnSquareIcon, { className: "w-4 h-4 ml-2 text-wds-blue-3 stroke-2" })] }))] })), _jsx("div", Object.assign({ className: "flex flex-col space-y-2" }, { children: accounts.map((account, index) => (_jsxs("div", Object.assign({ className: "w-full flex flex-row space-x-4 bg-white rounded-lg px-6 py-5 border border-wds-gray-3 cursor-pointer hover:border-wds-gray-4 hover:bg-wds-gray-2", onClick: (e) => {
                                e.stopPropagation();
                                navigate(`/home/account/${account.id}?${searchParams.toString()}`);
                            } }, { children: [" ", _jsx(AccountLogo, { account: account, className: "h-8 w-8 rounded-full" }), _jsx("div", Object.assign({ className: "font-bold text-lg w-full content-center" }, { children: account.account_name || account.name })), _jsx("div", Object.assign({ className: "flex flex-col justify-center items-center" }, { children: _jsx(ArrowRightIcon, { className: "text-wds-blue-3 h-6 w-6" }) }))] }), index))) }))] })), _jsx(Drawer, Object.assign({ open: hasAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate(`/home?${searchParams.toString()}`);
                } }, { children: _jsx(DrawerContent, Object.assign({ className: "h-full sm:max-w-none", hideCloseButton: true }, { children: _jsx(AccountPage, {}) })) }))] })));
};
export default {
    WideScreen: WideScreen,
};
